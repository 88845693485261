// 
// _table.scss
// 


.table thead th {
  border-bottom: none;
    border-top: none;
    font-weight: 600;
}
 
.table {
  th {
      color: var(--table-text-color) !important;
      font-weight: 400;
      background-color: transparent !important;
  }
  .table-light{
    color: $body-color;
    background-color: $table-head-bg;
  }
}

.table>:not(caption)>*>*{
  border-bottom-width: 0px;
}

.table>:not(:last-child)>:last-child>*{
}

.table-dark>:not(:last-child)>:last-child>*{
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}
.table tbody {
  tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }


  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }

  tr td {
    border-top-style: solid;
  }

  tr td:first-child {
    border-left-style: solid;
  }
}
.table td{
      color: var(--table-text-color) !important;
        border-top: none !important;
        background-color: transparent !important;
}
.table tr {
  background-color: transparent !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>*{
  --bs-table-accent-bg: var(--table-striped-bg) !important;
        color: var(--text-primary) !important;
}
.table-card-list{
  border-collapse:separate; 
  border-spacing:0 12px; 
  tr{
    background-color: $card-bg;
  }

}
.table > thead:last-child {
  display: none;
}