.dropdownColor {
  background-color: #2b32b2;
  border-color: #2b32b2;
}

.d-block {
  display: block;
}

.content_Fit {
  padding: calc(80px + 1.25rem) 0.625rem 160px;
}

.ml-1 {
  margin-left: 10px;
}

.mright-1 {
  margin-right: 10px;
}
.mw55 {
  min-width: 55px;
}
.h-4 {
  height: 45px !important;
}

.pagination {
  display: flex;
  justify-content: start;
}

.color-red {
  color: red;
  font-weight: 600;
}

.color-green {
  color: green;
  font-weight: 600;
}

.title-color {
  color: var(--text-primary);
}

.cursor-pointer {
  cursor: pointer !important;
}

.w-full {
  width: 100%;
}
.progress-bar {
  background-color: #1a96f0;
}
.btn {
  border-radius: 10px;
}
.button-color,
.button-color:active {
  background-color: var(--button-color) !important;
  color: white;
  border: none;
  border-radius: 10px;
}
.button-color:hover {
  background-color: var(--button-color);
}
.link_color,
.link_color:hover,
.link_underline,
.link_underline:hover {
  color: #1a96f0 !important;
}

.select__input-container css-qbdosj-Input {
  color: var(--text-primary);
}
#search_date .react-datepicker__input-container,
#search_date .react-datepicker__input-container input {
  background-color: var(--table-striped-bg);
}

.primary-button-color,
.auth_btn {
  background-color: #000 !important;
  color: white;
  border: none;
}
.primary-button-color:hover,
.auth_btn:hover {
  background-color: #4c4c4c !important;
}
.secondary-button-color {
  background-color: var(--secondary-btn-color) !important;
  color: var(--text-primary);
  border: var(--secondary-btn-border);
}
.secondary-button-color:hover,
.secondary-button-color:active {
  color: var(--text-primary) !important;
  background-color: var(--secondary-btn-hover-color) !important;
  border: 1px solid transparent !important;
}

label {
  margin-bottom: 0px;
}

.invalid-feedback {
  display: block;
}

.rounded {
  border-radius: 50% !important;
}

.modal-dialog {
  max-width: 600px;
}

.wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.error_msg {
  font-size: 12px;
  text-align: center;
  color: #ed193b;
}

.page-content .react-tel-input .form-control {
  width: 100%;
}
.form-control,
.select__input {
  color: var(--text-primary) !important;
  background-color: var(--datacard-background) !important;
  border: var(--search-input-border);
}
#social_media .form-control,
#social_media .form-control:disabled {
  background-color: var(--datacard-background) !important;
  border: 1px solid #ced4da;
}
.form-control:focus,
.form-control:active {
  color: #495057;
  background-color: var(--datacard-background) !important;
  outline: 0;
  box-shadow: none;
}
.react-select-custom,
.react-select-custom__single-value,
.react-select-extended,
.react-select-extended__single-value {
  color: var(--text-primary) !important;
}
.react-select-extended__menu .react-select-extended__menu-list {
  max-height: calc(100vh - 86vh) !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.react-select-custom__input,
.react-select-extended__input {
  border: 0px !important;
}
.react-select-custom__menu-list,
.react-select-extended__menu-list {
  background: var(--filter-input) !important;
}
.react-select-custom__option,
.react-select-extended__option {
  color: var(--text-primary) !important;
  background-color: transparent !important;
}
.react-select-custom__option:hover,
.react-select-extended__option:hover {
  background-color: var(--dropdown-value-background) !important;
}

.descriptionBox {
  border: 1px solid #e2e2e2;
  padding: 4px 6px;
  min-height: 70px;
  color: #74788d;
  background-color: #f5f6f8;
  border-radius: 6px;
}

.activeBadge {
  font-weight: 600;
  color: rgb(47, 104, 70);
  font-size: 13px;
  line-height: 1.43;
  background: rgb(234, 251, 231);
  padding: 4px 8px;
  border-radius: 4px;
}

.inActiveBadge {
  font-weight: 600;
  color: rgb(237 25 59);
  font-size: 13px;
  line-height: 1.43;
  background: rgb(237, 25, 59, 0.06);
  padding: 4px 8px;
  border-radius: 4px;
}

.clickableLabel {
  text-transform: capitalize;
  cursor: pointer;
}

.clickableLabel:hover {
  text-decoration: underline;
  color: #5656b5;
}

#close_icon {
  right: 30px;
  top: 25px;
}

#back_icon {
  left: 30px;
  top: 25px;
}
.h16px {
  height: 16px;
}
.password-eye {
  cursor: pointer;
  position: absolute;
  right: 8%;
  top: 58%;
}

.headernavsites a,
.headernavsites li {
  padding: 10px;
  list-style: none;
  cursor: pointer;
}

.headernavsites li {
  padding: 10px 0px;
}

.active_tab {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.navbar_custom {
  max-height: 100px;
}

.flex-wrap-activity {
  gap: 0.6rem;
  flex-wrap: wrap;
}

.shrink-menu .flex-wrap-activity {
  @media (min-width: 702px) {
    flex-wrap: nowrap;
  }
}

.grow-menu .flex-wrap-activity {
  @media (min-width: 882px) {
    flex-wrap: nowrap;
  }
}

/* css for dark theme starts */
.mainbody {
  background-color: var(--primary-background-color);
  height: auto;
  min-height: 100vh;
}
.authbody {
  background-color: var(--auth-background);
  height: auto;
  min-height: 100vh;
}
.text_primary,
.css-1dimb5e-singleValue {
  color: var(--text-primary) !important;
}
.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
  margin-left: 6px !important;
}
.view_icon {
  stroke: var(--text-primary);
}
.custom_dashboard_tabs {
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 600;
}
.custom_dashboard_tabs:hover {
  color: #1a96f0;
}
.line_height_normal {
  line-height: normal;
}
#data_card {
  background-color: var(--datacard-background) !important;
  border-radius: 0.25rem;
}
#data_card p,
.font_grey {
  color: var(--datacard-color) !important;
}
#data_card_val h4 {
  color: var(--text-primary) !important;
}
.table_background {
  background-color: var(--table-backround);
  border-radius: 8px;
}
.table_text {
  color: var(--text-table);
}
.dropdown-menu-end {
  /* border-radius: 16px; */
  background-color: var(--filter-background);
}
.css-t3ipsp-control,
.css-15lsz6c-indicatorContainer:hover,
.css-1fdsijx-ValueContainer:active,
.css-1xc3v61-indicatorContainer:hover,
.css-1fdsijx-ValueContainer,
.css-13cymwt-control,
.css-1xc3v61-indicatorContainer,
.form-select,
.form-select:focus {
  border-radius: 6px;
  background-color: var(--filter-input) !important;
  border-color: var(--filter-drop-border) !important;
  color: var(--text-primary);
}
.form-select:hover {
  background-color: #1a96f0;
}
option {
  color: var(--text-primary);
  background-color: var(--filter-dropdown-option);
}
.custom-label {
  color: var(--text-primary) !important;
}

input[type='radio' i]:disabled {
  cursor: default;
  background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), #1a96f0);
  color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
  border-color: rgba(118, 118, 118, 0.3);
}

/* scroll */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #bcbcbc;
}
.scrollbar::-webkit-scrollbar-track,
.guest-header {
  background-color: var(--maincontent-background);
}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  border-radius: 19px;
  width: 3px;
  height: 120px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 25px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-bg);
}
.overflow_wrap {
  overflow-wrap: anywhere;
}
.radio-button-valid {
  color: var(--text-primary) !important;
}
/* modal */
.modal-title {
  font-size: 25px;
  font-weight: 500;
}
.modal-content {
  background-color: var(--filter-background);
  border: none;
}
.modal-header {
  border-bottom: none;
}
#view_modal h5,
#view_modal h4,
#view_modal p,
#view_modal li {
  color: var(--text-primary) !important;
}
.delete_field i {
  color: var(--text-primary);
}

.w150 {
  width: 150px;
}
.w250 {
  width: 250px;
}

.disableApproveReject {
  cursor: not-allowed;
}

.approved-drop {
  font-weight: 600;
  color: #ffffff;
  background-color: rgb(47, 104, 70);
  font-size: larger;
}

#details_table {
  border: 1px solid var(--text-primary) !important;
}
#details_table .table tbody tr:nth-of-type(odd) td:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
#details_table .table tbody tr:nth-of-type(odd) td:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.chip_header {
  justify-content: end;
}
.save_price_chip {
  margin-right: 20px;
}
.editicon {
  right: 14px;
  top: 11px;
}
.pencil_icon {
  right: 10px;
  top: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .searchrange_div {
    flex-direction: column-reverse;
    justify-content: end !important;
    align-items: end !important;
  }
  .searchbar_div {
    justify-content: end;
    margin: 1rem;
    margin-right: 0px !important;
  }
  .search-box {
    min-width: 200px !important;
  }
  .dropdown_class {
    position: absolute;
  }
  .dropdown_div {
    position: relative;
  }
  .form_div,
  .search_range_component,
  .searchrange_div {
    width: 100%;
  }
  .chip_header {
    flex-direction: column-reverse;
  }
  .create_chip_btn {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-bottom: 10px;
  }
  .priceperchip_div {
    margin-right: 0px !important;
    justify-content: end;
  }
  .priceperchip {
    flex-direction: column;
  }
  .save_price_chip {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    margin-right: 0px;
  }
}
.priceperchip_div {
  margin-left: 10px;
  margin-right: 10px;
}
.website_search {
  width: 400px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .social_input {
    max-width: 200px;
  }
  .save_social {
    margin-top: 10px;
  }
  .website_search {
    width: -webkit-fill-available;
  }
}
.search-box {
  min-width: 300px;
}

/* root */
:root {
  --text-primary: black;
  --border-color: #d3d3d3;
  --sidebar-color: #fff;
  --sidebar-border-bottom: #d3d3d3;
  --primary-background-color: #e7e7e7;
  --maincontent-background: #fff;
  --table-backround: #fff;
  --datacard-background: #fff;
  --datacard-color: black;
  --auth-label-color: #ffffff;
  --profile-navbar-border-bottom: black;
  --text-table: black;
  --auth-background: #e7e7e7;
  --table-text-color: #000;
  --table-striped-bg: #f5f5f5;
  --search-input-border: 1px solid #ced4da;
  --auth-input-border: 1px solid #e0e0e0;
  --filter-background: #fff;
  --filter-input: #fff;
  --filter-dropdown-option: #fff;
  --button-color: #000;
  /* --button-color-hover:  */
  --secondary-btn-color: #fff;
  --secondary-btn-border: 1px solid #bdbdbd;
  --secondary-btn-hover-color: #f5f5f5;
  --scrollbar-bg: #bdbdbd;
  --filter-date-bg: #f5f5f5;
  --react-select-border: 1px solid hsl(0, 0%, 80%);
  --filter-drop-bg: #e7e7e7;
  --filter-drop-border: #ced4da;
  --dropdown-value-background: rgb(238 245 255);
}
[data-theme='dark'] {
  --text-primary: #fff;
  --border-color: #252525;
  --sidebar-color: #000000;
  --sidebar-border-bottom: #2b2b2b;
  --primary-background-color: #1d1d1d;
  --maincontent-background: #1d1d1d;
  --table-backround: #252525;
  --datacard-background: #262626;
  --datacard-color: #949494;
  --auth-label-color: #fff;
  --profile-navbar-border-bottom: #313131;
  --text-table: #e9e9e9;
  --auth-background: #000;
  --table-text-color: #e9e9e9;
  --table-striped-bg: #313131;
  --search-input-border: none !important;
  --auth-input-border: none;
  --filter-background: #313131;
  --filter-input: #262626;
  --filter-dropdown-option: #363636;
  --button-color: #000;
  --secondary-btn-color: #313131;
  --secondary-btn-border: 1px solid #464646;
  --secondary-btn-hover-color: #464646;
  --scrollbar-bg: #464646;
  --filter-date-bg: #262626;
  --react-select-border: none;
  --filter-drop-bg: #313131;
  --filter-drop-border: transparent;
  --dropdown-value-background: #000;
}
/* css for dark theme ends */
