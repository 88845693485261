.w_100px {
  width: 100px;
}

.skip_button,
.skip_button:hover {
  background: none;
  color: black;
  font-weight: 500;
  border: none;
}

.w_35 {
  width: 35%;
}

.w_120px {
  width: 120px;
}

.border_bottom {
  border-bottom: 1px solid;
}

.link_underline {
  text-decoration: underline !important;
}

.h_150px {
  height: 150px;
}

#website_link {
  padding: 0;
  border-bottom: 1px solid;
  color: #FF603A;
  background-color: transparent;
}

.btn_white {
  background: white;
  color: #5b73e8;
}

.react-tel-input .flag-dropdown {
  width: 16%;
}

.react-tel-input .selected-flag{
  width: unset !important;
}

.react-tel-input .selected-flag .arrow {
  top: 40% !important;
  margin-top: unset !important;
  width: 4px !important;
  left: 25px !important;
}

.react-datepicker__input-container {
  background-clip: padding-box;
  border: var(--search-input-border);
  border-radius: 0.375rem;
  padding: 7px;
  background-color: var(--filter-date-bg);
}

.react-datepicker__input-container input {
  border: none !important;
  font-size: .80rem;
  background-color: var(--filter-date-bg);
  color: var(--text-primary) !important;
}

.form-group.date_input_field, .form-group.questionnaire_input {
  width: 100%;
}

.dropdown-menu-end[style] {
  left: 15px !important;
  right: auto !important;
  margin-top: 5px;
}

.search-box {
  min-width: 300px;
}

#data_card_val span {
  color: #000;
}

.h30px,
#guest_header_logo {
  height: 30px;
}

.h50px {
  height: 50px;
}

.fw500 {
  font-weight: 500 !important;
}

#transaction_modal.modal-dialog {
  max-width: 900px;
}
.nft_box_1{
width: 30%;
}
.nft_box_2{
width: 70%;
}

.about_collection_text_area_h{
  height: 120px;
}

#clear_button {
  background-color: transparent;
  color: orange;
  font-weight: 600;
  border: none;
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  .form-group.questionnaire_input {
    width: 100%;
    margin-left: 0px !important;
  }
  #transaction_modal.modal-dialog {
    max-width: 600px;
  }

}